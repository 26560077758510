import {NAMES} from "./constants";

export const lossDataNewSet2 = {
    "Mar 06 2024": {
        [NAMES.TROOPS]: 420270,
        [NAMES.TANKS]: 6678,
        [NAMES.BMP_BTR]: 12728,
        [NAMES.ARTA]: 10308,
        [NAMES.PPO]: 701,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7921,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13479
    },
    "Mar 07 2024": {
        [NAMES.TROOPS]: 421430,
        [NAMES.TANKS]: 6695,
        [NAMES.BMP_BTR]: 12779,
        [NAMES.ARTA]: 10350,
        [NAMES.PPO]: 701,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7963,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13532
    },
    "Mar 08 2024": {
        [NAMES.TROOPS]: 422310,
        [NAMES.TANKS]: 6706,
        [NAMES.BMP_BTR]: 12798,
        [NAMES.ARTA]: 10375,
        [NAMES.PPO]: 704,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 7998,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13598
    },
    "Mar 09 2024": {
        [NAMES.TROOPS]: 423160,
        [NAMES.TANKS]: 6712,
        [NAMES.BMP_BTR]: 12823,
        [NAMES.ARTA]: 10428,
        [NAMES.PPO]: 707,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8041,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13683
    },
    "Mar 10 2024": {
        [NAMES.TROOPS]: 424060,
        [NAMES.TANKS]: 6731,
        [NAMES.BMP_BTR]: 12850,
        [NAMES.ARTA]: 10466,
        [NAMES.PPO]: 709,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8082,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13752
    },
    "Mar 11 2024": {
        [NAMES.TROOPS]: 424980,
        [NAMES.TANKS]: 6739,
        [NAMES.BMP_BTR]: 12874,
        [NAMES.ARTA]: 10499,
        [NAMES.PPO]: 711,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8141,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13826
    },
    "Mar 12 2024": {
        [NAMES.TROOPS]: 425890,
        [NAMES.TANKS]: 6747,
        [NAMES.BMP_BTR]: 12901,
        [NAMES.ARTA]: 10522,
        [NAMES.PPO]: 713,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8184,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13870
    },
    "Mar 13 2024": {
        [NAMES.TROOPS]: 426870,
        [NAMES.TANKS]: 6752,
        [NAMES.BMP_BTR]: 12921,
        [NAMES.ARTA]: 10554,
        [NAMES.PPO]: 715,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8205,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13932
    },
    "Mar 14 2024": {
        [NAMES.TROOPS]: 427840,
        [NAMES.TANKS]: 6757,
        [NAMES.BMP_BTR]: 12938,
        [NAMES.ARTA]: 10565,
        [NAMES.PPO]: 717,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8220,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13959
    },
    "Mar 15 2024": {
        [NAMES.TROOPS]: 428420,
        [NAMES.TANKS]: 6758,
        [NAMES.BMP_BTR]: 12949,
        [NAMES.ARTA]: 10580,
        [NAMES.PPO]: 717,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8254,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 13993
    },
    "Mar 16 2024": {
        [NAMES.TROOPS]: 429580,
        [NAMES.TANKS]: 6779,
        [NAMES.BMP_BTR]: 12973,
        [NAMES.ARTA]: 10606,
        [NAMES.PPO]: 719,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8268,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14024
    },
    "Mar 17 2024": {
        [NAMES.TROOPS]: 430740,
        [NAMES.TANKS]: 6790,
        [NAMES.BMP_BTR]: 12997,
        [NAMES.ARTA]: 10634,
        [NAMES.PPO]: 720,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8272,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14073
    },
    "Mar 18 2024": {
        [NAMES.TROOPS]: 431550,
        [NAMES.TANKS]: 6809,
        [NAMES.BMP_BTR]: 13014,
        [NAMES.ARTA]: 10668,
        [NAMES.PPO]: 720,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8308,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14141
    },
    "Mar 19 2024": {
        [NAMES.TROOPS]: 432390,
        [NAMES.TANKS]: 6819,
        [NAMES.BMP_BTR]: 13049,
        [NAMES.ARTA]: 10698,
        [NAMES.PPO]: 721,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8318,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14191
    },
    "Mar 20 2024": {
        [NAMES.TROOPS]: 433090,
        [NAMES.TANKS]: 6828,
        [NAMES.BMP_BTR]: 13058,
        [NAMES.ARTA]: 10714,
        [NAMES.PPO]: 721,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8355,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14198
    },
    "Mar 21 2024": {
        [NAMES.TROOPS]: 433840,
        [NAMES.TANKS]: 6832,
        [NAMES.BMP_BTR]: 13074,
        [NAMES.ARTA]: 10740,
        [NAMES.PPO]: 721,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8365,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14242
    },
    "Mar 22 2024": {
        [NAMES.TROOPS]: 434710,
        [NAMES.TANKS]: 6840,
        [NAMES.BMP_BTR]: 13111,
        [NAMES.ARTA]: 10775,
        [NAMES.PPO]: 723,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8388,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14287
    },
    "Mar 23 2024": {
        [NAMES.TROOPS]: 435760,
        [NAMES.TANKS]: 6852,
        [NAMES.BMP_BTR]: 13130,
        [NAMES.ARTA]: 10811,
        [NAMES.PPO]: 723,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8463,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14344
    },
    "Mar 24 2024": {
        [NAMES.TROOPS]: 436750,
        [NAMES.TANKS]: 6876,
        [NAMES.BMP_BTR]: 13158,
        [NAMES.ARTA]: 10855,
        [NAMES.PPO]: 723,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8510,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14407
    },
    "Mar 25 2024": {
        [NAMES.TROOPS]: 437390,
        [NAMES.TANKS]: 6887,
        [NAMES.BMP_BTR]: 13183,
        [NAMES.ARTA]: 10877,
        [NAMES.PPO]: 726,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8539,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14454
    },
    "Mar 26 2024": {
        [NAMES.TROOPS]: 438160,
        [NAMES.TANKS]: 6893,
        [NAMES.BMP_BTR]: 13207,
        [NAMES.ARTA]: 10904,
        [NAMES.PPO]: 727,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8553,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14498
    },
    "Mar 27 2024": {
        [NAMES.TROOPS]: 439190,
        [NAMES.TANKS]: 6904,
        [NAMES.BMP_BTR]: 13216,
        [NAMES.ARTA]: 10931,
        [NAMES.PPO]: 728,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8578,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14539
    },
    "Mar 28 2024": {
        [NAMES.TROOPS]: 439970,
        [NAMES.TANKS]: 6914,
        [NAMES.BMP_BTR]: 13237,
        [NAMES.ARTA]: 10963,
        [NAMES.PPO]: 729,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8600,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14595
    },
    "Mar 29 2024": {
        [NAMES.TROOPS]: 440790,
        [NAMES.TANKS]: 6922,
        [NAMES.BMP_BTR]: 13264,
        [NAMES.ARTA]: 10991,
        [NAMES.PPO]: 735,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8656,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14645
    },
    "Mar 30 2024": {
        [NAMES.TROOPS]: 441520,
        [NAMES.TANKS]: 6951,
        [NAMES.BMP_BTR]: 13284,
        [NAMES.ARTA]: 11006,
        [NAMES.PPO]: 736,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8701,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14670
    },
    "Mar 31 2024": {
        [NAMES.TROOPS]: 442170,
        [NAMES.TANKS]: 6966,
        [NAMES.BMP_BTR]: 13304,
        [NAMES.ARTA]: 11050,
        [NAMES.PPO]: 743,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8731,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14717
    },
    "Apr 01 2024": {
        [NAMES.TROOPS]: 442880,
        [NAMES.TANKS]: 6986,
        [NAMES.BMP_BTR]: 13321,
        [NAMES.ARTA]: 11082,
        [NAMES.PPO]: 744,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8757,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14752
    },
    "Apr 02 2024": {
        [NAMES.TROOPS]: 443660,
        [NAMES.TANKS]: 6998,
        [NAMES.BMP_BTR]: 13350,
        [NAMES.ARTA]: 11112,
        [NAMES.PPO]: 744,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8779,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14783
    },
    "Apr 03 2024": {
        [NAMES.TROOPS]: 444370,
        [NAMES.TANKS]: 7009,
        [NAMES.BMP_BTR]: 13368,
        [NAMES.ARTA]: 11142,
        [NAMES.PPO]: 745,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8796,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14813
    },
    "Apr 04 2024": {
        [NAMES.TROOPS]: 445040,
        [NAMES.TANKS]: 7018,
        [NAMES.BMP_BTR]: 13386,
        [NAMES.ARTA]: 11171,
        [NAMES.PPO]: 746,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8817,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14861
    },
    "Apr 05 2024": {
        [NAMES.TROOPS]: 445900,
        [NAMES.TANKS]: 7033,
        [NAMES.BMP_BTR]: 13459,
        [NAMES.ARTA]: 11221,
        [NAMES.PPO]: 747,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8847,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14922
    },
    "Apr 06 2024": {
        [NAMES.TROOPS]: 446690,
        [NAMES.TANKS]: 7057,
        [NAMES.BMP_BTR]: 13497,
        [NAMES.ARTA]: 11262,
        [NAMES.PPO]: 749,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8895,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 14992
    },
    "Apr 07 2024": {
        [NAMES.TROOPS]: 447510,
        [NAMES.TANKS]: 7074,
        [NAMES.BMP_BTR]: 13551,
        [NAMES.ARTA]: 11316,
        [NAMES.PPO]: 749,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8956,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15071
    },
    "Apr 08 2024": {
        [NAMES.TROOPS]: 448400,
        [NAMES.TANKS]: 7087,
        [NAMES.BMP_BTR]: 13575,
        [NAMES.ARTA]: 11356,
        [NAMES.PPO]: 752,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 8996,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15110
    },
    "Apr 09 2024": {
        [NAMES.TROOPS]: 449250,
        [NAMES.TANKS]: 7110,
        [NAMES.BMP_BTR]: 13620,
        [NAMES.ARTA]: 11386,
        [NAMES.PPO]: 753,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9033,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15181
    },
    "Apr 10 2024": {
        [NAMES.TROOPS]: 450080,
        [NAMES.TANKS]: 7132,
        [NAMES.BMP_BTR]: 13667,
        [NAMES.ARTA]: 11404,
        [NAMES.PPO]: 753,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9070,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15248
    },
    "Apr 11 2024": {
        [NAMES.TROOPS]: 450890,
        [NAMES.TANKS]: 7137,
        [NAMES.BMP_BTR]: 13679,
        [NAMES.ARTA]: 11452,
        [NAMES.PPO]: 754,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9104,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15298
    },
    "Apr 12 2024": {
        [NAMES.TROOPS]: 451730,
        [NAMES.TANKS]: 7146,
        [NAMES.BMP_BTR]: 13698,
        [NAMES.ARTA]: 11486,
        [NAMES.PPO]: 755,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9161,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15339
    },
    "Apr 13 2024": {
        [NAMES.TROOPS]: 452760,
        [NAMES.TANKS]: 7169,
        [NAMES.BMP_BTR]: 13745,
        [NAMES.ARTA]: 11518,
        [NAMES.PPO]: 756,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9201,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15396
    },
    "Apr 14 2024": {
        [NAMES.TROOPS]: 453650,
        [NAMES.TANKS]: 7174,
        [NAMES.BMP_BTR]: 13765,
        [NAMES.ARTA]: 11552,
        [NAMES.PPO]: 758,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9235,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15459
    },
    "Apr 15 2024": {
        [NAMES.TROOPS]: 454420,
        [NAMES.TANKS]: 7180,
        [NAMES.BMP_BTR]: 13796,
        [NAMES.ARTA]: 11593,
        [NAMES.PPO]: 758,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9266,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15510
    },
    "Apr 16 2024": {
        [NAMES.TROOPS]: 455340,
        [NAMES.TANKS]: 7189,
        [NAMES.BMP_BTR]: 13809,
        [NAMES.ARTA]: 11609,
        [NAMES.PPO]: 759,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9277,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15563
    },
    "Apr 17 2024": {
        [NAMES.TROOPS]: 456050,
        [NAMES.TANKS]: 7193,
        [NAMES.BMP_BTR]: 13827,
        [NAMES.ARTA]: 11624,
        [NAMES.PPO]: 760,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9279,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15587
    },
    "Apr 18 2024": {
        [NAMES.TROOPS]: 456960,
        [NAMES.TANKS]: 7196,
        [NAMES.BMP_BTR]: 13835,
        [NAMES.ARTA]: 11637,
        [NAMES.PPO]: 760,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9284,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15618
    },
    "Apr 19 2024": {
        [NAMES.TROOPS]: 457830,
        [NAMES.TANKS]: 7205,
        [NAMES.BMP_BTR]: 13852,
        [NAMES.ARTA]: 11658,
        [NAMES.PPO]: 762,
        [NAMES.JETS]: 347,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9311,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15671
    },
    "Apr 20 2024": {
        [NAMES.TROOPS]: 458580,
        [NAMES.TANKS]: 7213,
        [NAMES.BMP_BTR]: 13873,
        [NAMES.ARTA]: 11678,
        [NAMES.PPO]: 763,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9341,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15717
    },
    "Apr 21 2024": {
        [NAMES.TROOPS]: 459530,
        [NAMES.TANKS]: 7229,
        [NAMES.BMP_BTR]: 13896,
        [NAMES.ARTA]: 11719,
        [NAMES.PPO]: 767,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9379,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15785
    },
    "Apr 22 2024": {
        [NAMES.TROOPS]: 460260,
        [NAMES.TANKS]: 7236,
        [NAMES.BMP_BTR]: 13904,
        [NAMES.ARTA]: 11736,
        [NAMES.PPO]: 768,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9384,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15812
    },
    "Apr 23 2024": {
        [NAMES.TROOPS]: 461060,
        [NAMES.TANKS]: 7241,
        [NAMES.BMP_BTR]: 13916,
        [NAMES.ARTA]: 11765,
        [NAMES.PPO]: 769,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9407,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15845
    },
    "Apr 24 2024": {
        [NAMES.TROOPS]: 461940,
        [NAMES.TANKS]: 7242,
        [NAMES.BMP_BTR]: 13928,
        [NAMES.ARTA]: 11808,
        [NAMES.PPO]: 771,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9439,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15892
    },
    "Apr 25 2024": {
        [NAMES.TROOPS]: 462980,
        [NAMES.TANKS]: 7255,
        [NAMES.BMP_BTR]: 13942,
        [NAMES.ARTA]: 11836,
        [NAMES.PPO]: 772,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9449,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15949
    },
    "Apr 26 2024": {
        [NAMES.TROOPS]: 463930,
        [NAMES.TANKS]: 7262,
        [NAMES.BMP_BTR]: 13957,
        [NAMES.ARTA]: 11867,
        [NAMES.PPO]: 772,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9461,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 15984
    },
    "Apr 27 2024": {
        [NAMES.TROOPS]: 465050,
        [NAMES.TANKS]: 7268,
        [NAMES.BMP_BTR]: 13971,
        [NAMES.ARTA]: 11905,
        [NAMES.PPO]: 775,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9485,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16019
    },
    "Apr 28 2024": {
        [NAMES.TROOPS]: 466150,
        [NAMES.TANKS]: 7279,
        [NAMES.BMP_BTR]: 13991,
        [NAMES.ARTA]: 11948,
        [NAMES.PPO]: 776,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9507,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16065
    },
    "Apr 29 2024": {
        [NAMES.TROOPS]: 467470,
        [NAMES.TANKS]: 7285,
        [NAMES.BMP_BTR]: 14007,
        [NAMES.ARTA]: 11985,
        [NAMES.PPO]: 778,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9528,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16109
    },
    "Apr 30 2024": {
        [NAMES.TROOPS]: 468720,
        [NAMES.TANKS]: 7307,
        [NAMES.BMP_BTR]: 14046,
        [NAMES.ARTA]: 12011,
        [NAMES.PPO]: 779,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9531,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16142
    },
    "May 01 2024": {
        [NAMES.TROOPS]: 469840,
        [NAMES.TANKS]: 7312,
        [NAMES.BMP_BTR]: 14067,
        [NAMES.ARTA]: 12024,
        [NAMES.PPO]: 780,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9538,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16175
    },
    "May 02 2024": {
        [NAMES.TROOPS]: 470870,
        [NAMES.TANKS]: 7332,
        [NAMES.BMP_BTR]: 14096,
        [NAMES.ARTA]: 12044,
        [NAMES.PPO]: 784,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9561,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16224
    },
    "May 03 2024": {
        [NAMES.TROOPS]: 472140,
        [NAMES.TANKS]: 7354,
        [NAMES.BMP_BTR]: 14129,
        [NAMES.ARTA]: 12102,
        [NAMES.PPO]: 786,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9580,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16266
    },
    "May 04 2024": {
        [NAMES.TROOPS]: 473400,
        [NAMES.TANKS]: 7366,
        [NAMES.BMP_BTR]: 14156,
        [NAMES.ARTA]: 12148,
        [NAMES.PPO]: 788,
        [NAMES.JETS]: 348,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9611,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16337
    },
    "May 05 2024": {
        [NAMES.TROOPS]: 474260,
        [NAMES.TANKS]: 7375,
        [NAMES.BMP_BTR]: 14179,
        [NAMES.ARTA]: 12212,
        [NAMES.PPO]: 790,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9636,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16418
    },
    "May 06 2024": {
        [NAMES.TROOPS]: 475300,
        [NAMES.TANKS]: 7380,
        [NAMES.BMP_BTR]: 14213,
        [NAMES.ARTA]: 12250,
        [NAMES.PPO]: 791,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9683,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16477
    },
    "May 07 2024": {
        [NAMES.TROOPS]: 476460,
        [NAMES.TANKS]: 7405,
        [NAMES.BMP_BTR]: 14227,
        [NAMES.ARTA]: 12287,
        [NAMES.PPO]: 792,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9717,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16509
    },
    "May 08 2024": {
        [NAMES.TROOPS]: 477430,
        [NAMES.TANKS]: 7418,
        [NAMES.BMP_BTR]: 14246,
        [NAMES.ARTA]: 12317,
        [NAMES.PPO]: 792,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9728,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16549
    },
    "May 09 2024": {
        [NAMES.TROOPS]: 478730,
        [NAMES.TANKS]: 7429,
        [NAMES.BMP_BTR]: 14281,
        [NAMES.ARTA]: 12340,
        [NAMES.PPO]: 793,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9775,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16618
    },
    "May 10 2024": {
        [NAMES.TROOPS]: 479710,
        [NAMES.TANKS]: 7434,
        [NAMES.BMP_BTR]: 14313,
        [NAMES.ARTA]: 12387,
        [NAMES.PPO]: 795,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9826,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16691
    },
    "May 11 2024": {
        [NAMES.TROOPS]: 481030,
        [NAMES.TANKS]: 7449,
        [NAMES.BMP_BTR]: 14353,
        [NAMES.ARTA]: 12442,
        [NAMES.PPO]: 796,
        [NAMES.JETS]: 349,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9868,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16755
    },
    "May 12 2024": {
        [NAMES.TROOPS]: 482290,
        [NAMES.TANKS]: 7454,
        [NAMES.BMP_BTR]: 14375,
        [NAMES.ARTA]: 12472,
        [NAMES.PPO]: 797,
        [NAMES.JETS]: 350,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9910,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16819
    },
    "May 13 2024": {
        [NAMES.TROOPS]: 484030,
        [NAMES.TANKS]: 7485,
        [NAMES.BMP_BTR]: 14417,
        [NAMES.ARTA]: 12487,
        [NAMES.PPO]: 797,
        [NAMES.JETS]: 350,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9936,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16878
    },
    "May 14 2024": {
        [NAMES.TROOPS]: 485430,
        [NAMES.TANKS]: 7496,
        [NAMES.BMP_BTR]: 14460,
        [NAMES.ARTA]: 12515,
        [NAMES.PPO]: 798,
        [NAMES.JETS]: 351,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 9985,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16907
    },
    "May 15 2024": {
        [NAMES.TROOPS]: 486940,
        [NAMES.TANKS]: 7510,
        [NAMES.BMP_BTR]: 14508,
        [NAMES.ARTA]: 12538,
        [NAMES.PPO]: 798,
        [NAMES.JETS]: 351,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 10015,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 16955
    },
    "May 16 2024": {
        [NAMES.TROOPS]: 488460,
        [NAMES.TANKS]: 7529,
        [NAMES.BMP_BTR]: 14538,
        [NAMES.ARTA]: 12565,
        [NAMES.PPO]: 798,
        [NAMES.JETS]: 351,
        [NAMES.HELI]: 325,
        [NAMES.UAV]: 10028,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 17048
    },
    "May 17 2024": {
        [NAMES.TROOPS]: 489870,
        [NAMES.TANKS]: 7547,
        [NAMES.BMP_BTR]: 14552,
        [NAMES.ARTA]: 12603,
        [NAMES.PPO]: 800,
        [NAMES.JETS]: 353,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10073,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 17104
    },
    "May 18 2024": {
        [NAMES.TROOPS]: 491080,
        [NAMES.TANKS]: 7560,
        [NAMES.BMP_BTR]: 14595,
        [NAMES.ARTA]: 12639,
        [NAMES.PPO]: 801,
        [NAMES.JETS]: 354,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10108,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 17169
    },
    "May 19 2024": {
        [NAMES.TROOPS]: 492290,
        [NAMES.TANKS]: 7576,
        [NAMES.BMP_BTR]: 14630,
        [NAMES.ARTA]: 12687,
        [NAMES.PPO]: 803,
        [NAMES.JETS]: 354,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10155,
        [NAMES.SHIPS]: 27,
        [NAMES.AUTO]: 17251
    },
    "May 20 2024": {
        [NAMES.TROOPS]: 493690,
        [NAMES.TANKS]: 7590,
        [NAMES.BMP_BTR]: 14665,
        [NAMES.ARTA]: 12737,
        [NAMES.PPO]: 807,
        [NAMES.JETS]: 354,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10236,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17311
    },
    "May 21 2024": {
        [NAMES.TROOPS]: 495070,
        [NAMES.TANKS]: 7605,
        [NAMES.BMP_BTR]: 14699,
        [NAMES.ARTA]: 12779,
        [NAMES.PPO]: 811,
        [NAMES.JETS]: 354,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10290,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17383
    },
    "May 22 2024": {
        [NAMES.TROOPS]: 496370,
        [NAMES.TANKS]: 7611,
        [NAMES.BMP_BTR]: 14721,
        [NAMES.ARTA]: 12820,
        [NAMES.PPO]: 812,
        [NAMES.JETS]: 354,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10346,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17442
    },
    "May 23 2024": {
        [NAMES.TROOPS]: 497700,
        [NAMES.TANKS]: 7622,
        [NAMES.BMP_BTR]: 14748,
        [NAMES.ARTA]: 12860,
        [NAMES.PPO]: 813,
        [NAMES.JETS]: 355,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10391,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17513
    },
    "May 24 2024": {
        [NAMES.TROOPS]: 498940,
        [NAMES.TANKS]: 7635,
        [NAMES.BMP_BTR]: 14775,
        [NAMES.ARTA]: 12902,
        [NAMES.PPO]: 813,
        [NAMES.JETS]: 356,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10401,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17569
    },
    "May 25 2024": {
        [NAMES.TROOPS]: 500080,
        [NAMES.TANKS]: 7650,
        [NAMES.BMP_BTR]: 14786,
        [NAMES.ARTA]: 12929,
        [NAMES.PPO]: 814,
        [NAMES.JETS]: 356,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10414,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17608
    },
    "May 26 2024": {
        [NAMES.TROOPS]: 501190,
        [NAMES.TANKS]: 7662,
        [NAMES.BMP_BTR]: 14803,
        [NAMES.ARTA]: 12953,
        [NAMES.PPO]: 814,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10425,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17639
    },
    "May 27 2024": {
        [NAMES.TROOPS]: 502340,
        [NAMES.TANKS]: 7671,
        [NAMES.BMP_BTR]: 14818,
        [NAMES.ARTA]: 12981,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10467,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17694
    },
    "May 28 2024": {
        [NAMES.TROOPS]: 503800,
        [NAMES.TANKS]: 7692,
        [NAMES.BMP_BTR]: 14858,
        [NAMES.ARTA]: 13029,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10482,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17740
    },
    "May 29 2024": {
        [NAMES.TROOPS]: 505100,
        [NAMES.TANKS]: 7700,
        [NAMES.BMP_BTR]: 14891,
        [NAMES.ARTA]: 13066,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10510,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17796
    },
    "May 30 2024": {
        [NAMES.TROOPS]: 506260,
        [NAMES.TANKS]: 7710,
        [NAMES.BMP_BTR]: 14913,
        [NAMES.ARTA]: 13101,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10532,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17849
    },
    "May 31 2024": {
        [NAMES.TROOPS]: 507650,
        [NAMES.TANKS]: 7728,
        [NAMES.BMP_BTR]: 14935,
        [NAMES.ARTA]: 13140,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10587,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 17905
    },
    "Jun 01 2024": {
        [NAMES.TROOPS]: 508780,
        [NAMES.TANKS]: 7740,
        [NAMES.BMP_BTR]: 14947,
        [NAMES.ARTA]: 13184,
        [NAMES.PPO]: 815,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10617,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18006
    },
    "Jun 02 2024": {
        [NAMES.TROOPS]: 509860,
        [NAMES.TANKS]: 7765,
        [NAMES.BMP_BTR]: 14980,
        [NAMES.ARTA]: 13233,
        [NAMES.PPO]: 821,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10699,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18092
    },
    "Jun 03 2024": {
        [NAMES.TROOPS]: 511130,
        [NAMES.TANKS]: 7779,
        [NAMES.BMP_BTR]: 15002,
        [NAMES.ARTA]: 13280,
        [NAMES.PPO]: 824,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10739,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18159
    },
    "Jun 04 2024": {
        [NAMES.TROOPS]: 512420,
        [NAMES.TANKS]: 7794,
        [NAMES.BMP_BTR]: 15020,
        [NAMES.ARTA]: 13345,
        [NAMES.PPO]: 827,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10766,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18228
    },
    "Jun 05 2024": {
        [NAMES.TROOPS]: 513700,
        [NAMES.TANKS]: 7806,
        [NAMES.BMP_BTR]: 15036,
        [NAMES.ARTA]: 13385,
        [NAMES.PPO]: 830,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10805,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18297
    },
    "Jun 06 2024": {
        [NAMES.TROOPS]: 515000,
        [NAMES.TANKS]: 7828,
        [NAMES.BMP_BTR]: 15076,
        [NAMES.ARTA]: 13433,
        [NAMES.PPO]: 831,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10846,
        [NAMES.SHIPS]: 28,
        [NAMES.AUTO]: 18360
    },
    "Jun 07 2024": {
        [NAMES.TROOPS]: 516080,
        [NAMES.TANKS]: 7834,
        [NAMES.BMP_BTR]: 15096,
        [NAMES.ARTA]: 13497,
        [NAMES.PPO]: 833,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10886,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18360
    },
    "Jun 08 2024": {
        [NAMES.TROOPS]: 517290,
        [NAMES.TANKS]: 7843,
        [NAMES.BMP_BTR]: 15105,
        [NAMES.ARTA]: 13533,
        [NAMES.PPO]: 834,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10945,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18484
    },
    "Jun 09 2024": {
        [NAMES.TROOPS]: 518560,
        [NAMES.TANKS]: 7869,
        [NAMES.BMP_BTR]: 15131,
        [NAMES.ARTA]: 13593,
        [NAMES.PPO]: 836,
        [NAMES.JETS]: 357,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 10982,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18562
    },
    "Jun 10 2024": {
        [NAMES.TROOPS]: 519750,
        [NAMES.TANKS]: 7879,
        [NAMES.BMP_BTR]: 15144,
        [NAMES.ARTA]: 13644,
        [NAMES.PPO]: 837,
        [NAMES.JETS]: 358,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11010,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18618
    },
    "Jun 11 2024": {
        [NAMES.TROOPS]: 520850,
        [NAMES.TANKS]: 7902,
        [NAMES.BMP_BTR]: 15176,
        [NAMES.ARTA]: 13690,
        [NAMES.PPO]: 842,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11023,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18676
    },
    "Jun 12 2024": {
        [NAMES.TROOPS]: 521830,
        [NAMES.TANKS]: 7911,
        [NAMES.BMP_BTR]: 15187,
        [NAMES.ARTA]: 13736,
        [NAMES.PPO]: 844,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11042,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18736
    },
    "Jun 13 2024": {
        [NAMES.TROOPS]: 522810,
        [NAMES.TANKS]: 7928,
        [NAMES.BMP_BTR]: 15208,
        [NAMES.ARTA]: 13770,
        [NAMES.PPO]: 846,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11075,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18794
    },
    "Jun 14 2024": {
        [NAMES.TROOPS]: 524060,
        [NAMES.TANKS]: 7936,
        [NAMES.BMP_BTR]: 15234,
        [NAMES.ARTA]: 13818,
        [NAMES.PPO]: 849,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11097,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18854
    },
    "Jun 15 2024": {
        [NAMES.TROOPS]: 525150,
        [NAMES.TANKS]: 7956,
        [NAMES.BMP_BTR]: 15263,
        [NAMES.ARTA]: 13855,
        [NAMES.PPO]: 853,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11148,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18911
    },
    "Jun 16 2024": {
        [NAMES.TROOPS]: 526310,
        [NAMES.TANKS]: 7956,
        [NAMES.BMP_BTR]: 15269,
        [NAMES.ARTA]: 13913,
        [NAMES.PPO]: 853,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11159,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18967
    },
    "Jun 17 2024": {
        [NAMES.TROOPS]: 527390,
        [NAMES.TANKS]: 7958,
        [NAMES.BMP_BTR]: 15287,
        [NAMES.ARTA]: 13927,
        [NAMES.PPO]: 853,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11167,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 18991
    },
    "Jun 18 2024": {
        [NAMES.TROOPS]: 528620,
        [NAMES.TANKS]: 7974,
        [NAMES.BMP_BTR]: 15307,
        [NAMES.ARTA]: 13959,
        [NAMES.PPO]: 853,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11187,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19031
    },
    "Jun 19 2024": {
        [NAMES.TROOPS]: 529750,
        [NAMES.TANKS]: 7984,
        [NAMES.BMP_BTR]: 15319,
        [NAMES.ARTA]: 14007,
        [NAMES.PPO]: 857,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11221,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19078
    },
    "Jun 20 2024": {
        [NAMES.TROOPS]: 530920,
        [NAMES.TANKS]: 7987,
        [NAMES.BMP_BTR]: 15337,
        [NAMES.ARTA]: 14052,
        [NAMES.PPO]: 859,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11260,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19134
    },
    "Jun 21 2024": {
        [NAMES.TROOPS]: 531980,
        [NAMES.TANKS]: 8001,
        [NAMES.BMP_BTR]: 15372,
        [NAMES.ARTA]: 14106,
        [NAMES.PPO]: 861,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11260,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19181
    },
    "Jun 22 2024": {
        [NAMES.TROOPS]: 533090,
        [NAMES.TANKS]: 8009,
        [NAMES.BMP_BTR]: 15383,
        [NAMES.ARTA]: 14134,
        [NAMES.PPO]: 861,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11305,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19204
    },
    "Jun 23 2024": {
        [NAMES.TROOPS]: 534360,
        [NAMES.TANKS]: 8019,
        [NAMES.BMP_BTR]: 15398,
        [NAMES.ARTA]: 14195,
        [NAMES.PPO]: 863,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11355,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19248
    },
    "Jun 24 2024": {
        [NAMES.TROOPS]: 535660,
        [NAMES.TANKS]: 8031,
        [NAMES.BMP_BTR]: 15413,
        [NAMES.ARTA]: 14246,
        [NAMES.PPO]: 863,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11382,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19304
    },
    "Jun 25 2024": {
        [NAMES.TROOPS]: 536840,
        [NAMES.TANKS]: 8035,
        [NAMES.BMP_BTR]: 15431,
        [NAMES.ARTA]: 14281,
        [NAMES.PPO]: 863,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11413,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19362
    },
    "Jun 26 2024": {
        [NAMES.TROOPS]: 538060,
        [NAMES.TANKS]: 8039,
        [NAMES.BMP_BTR]: 15450,
        [NAMES.ARTA]: 14321,
        [NAMES.PPO]: 863,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11435,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19407
    },
    "Jun 27 2024": {
        [NAMES.TROOPS]: 539320,
        [NAMES.TANKS]: 8042,
        [NAMES.BMP_BTR]: 15459,
        [NAMES.ARTA]: 14363,
        [NAMES.PPO]: 868,
        [NAMES.JETS]: 359,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11459,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19468
    },
    "Jun 28 2024": {
        [NAMES.TROOPS]: 540490,
        [NAMES.TANKS]: 8066,
        [NAMES.BMP_BTR]: 15480,
        [NAMES.ARTA]: 14423,
        [NAMES.PPO]: 871,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11509,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19514
    },
    "Jun 29 2024": {
        [NAMES.TROOPS]: 541560,
        [NAMES.TANKS]: 8073,
        [NAMES.BMP_BTR]: 15505,
        [NAMES.ARTA]: 14480,
        [NAMES.PPO]: 871,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11538,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19568
    },
    "Jun 30 2024": {
        [NAMES.TROOPS]: 542700,
        [NAMES.TANKS]: 8080,
        [NAMES.BMP_BTR]: 15524,
        [NAMES.ARTA]: 14533,
        [NAMES.PPO]: 873,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11584,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19643
    },
    "Jul 01 2024": {
        [NAMES.TROOPS]: 543810,
        [NAMES.TANKS]: 8099,
        [NAMES.BMP_BTR]: 15550,
        [NAMES.ARTA]: 14599,
        [NAMES.PPO]: 874,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11620,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19713
    },
    "Jul 02 2024": {
        [NAMES.TROOPS]: 545090,
        [NAMES.TANKS]: 8107,
        [NAMES.BMP_BTR]: 15566,
        [NAMES.ARTA]: 14655,
        [NAMES.PPO]: 874,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11641,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19787
    },
    "Jul 03 2024": {
        [NAMES.TROOPS]: 546270,
        [NAMES.TANKS]: 8123,
        [NAMES.BMP_BTR]: 15583,
        [NAMES.ARTA]: 14712,
        [NAMES.PPO]: 876,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11668,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19850
    },
    "Jul 04 2024": {
        [NAMES.TROOPS]: 547470,
        [NAMES.TANKS]: 8132,
        [NAMES.BMP_BTR]: 15600,
        [NAMES.ARTA]: 14777,
        [NAMES.PPO]: 878,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11694,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19923
    },
    "Jul 05 2024": {
        [NAMES.TROOPS]: 548580,
        [NAMES.TANKS]: 8142,
        [NAMES.BMP_BTR]: 15611,
        [NAMES.ARTA]: 14831,
        [NAMES.PPO]: 878,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11751,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 19977
    },
    "Jul 06 2024": {
        [NAMES.TROOPS]: 549840,
        [NAMES.TANKS]: 8153,
        [NAMES.BMP_BTR]: 15629,
        [NAMES.ARTA]: 14897,
        [NAMES.PPO]: 878,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11809,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20053
    },
    "Jul 07 2024": {
        [NAMES.TROOPS]: 550990,
        [NAMES.TANKS]: 8155,
        [NAMES.BMP_BTR]: 15645,
        [NAMES.ARTA]: 14937,
        [NAMES.PPO]: 879,
        [NAMES.JETS]: 360,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11862,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20103
    },
    "Jul 08 2024": {
        [NAMES.TROOPS]: 552190,
        [NAMES.TANKS]: 8171,
        [NAMES.BMP_BTR]: 15685,
        [NAMES.ARTA]: 14966,
        [NAMES.PPO]: 880,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11893,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20150
    },
    "Jul 09 2024": {
        [NAMES.TROOPS]: 553410,
        [NAMES.TANKS]: 8178,
        [NAMES.BMP_BTR]: 15704,
        [NAMES.ARTA]: 15015,
        [NAMES.PPO]: 880,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11922,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20212
    },
    "Jul 10 2024": {
        [NAMES.TROOPS]: 554510,
        [NAMES.TANKS]: 8180,
        [NAMES.BMP_BTR]: 15715,
        [NAMES.ARTA]: 15051,
        [NAMES.PPO]: 883,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 11973,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20269
    },
    "Jul 11 2024": {
        [NAMES.TROOPS]: 555620,
        [NAMES.TANKS]: 8182,
        [NAMES.BMP_BTR]: 15732,
        [NAMES.ARTA]: 15110,
        [NAMES.PPO]: 886,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12009,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20325
    },
    "Jul 12 2024": {
        [NAMES.TROOPS]: 556650,
        [NAMES.TANKS]: 8191,
        [NAMES.BMP_BTR]: 15755,
        [NAMES.ARTA]: 15158,
        [NAMES.PPO]: 888,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12035,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20409
    },
    "Jul 13 2024": {
        [NAMES.TROOPS]: 557770,
        [NAMES.TANKS]: 8199,
        [NAMES.BMP_BTR]: 15779,
        [NAMES.ARTA]: 15216,
        [NAMES.PPO]: 889,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12069,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20461
    },
    "Jul 14 2024": {
        [NAMES.TROOPS]: 559090,
        [NAMES.TANKS]: 8206,
        [NAMES.BMP_BTR]: 15811,
        [NAMES.ARTA]: 15262,
        [NAMES.PPO]: 890,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12108,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20538
    },
    "Jul 15 2024": {
        [NAMES.TROOPS]: 560290,
        [NAMES.TANKS]: 8214,
        [NAMES.BMP_BTR]: 15826,
        [NAMES.ARTA]: 15324,
        [NAMES.PPO]: 892,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12148,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20623
    },
    "Jul 16 2024": {
        [NAMES.TROOPS]: 561400,
        [NAMES.TANKS]: 8227,
        [NAMES.BMP_BTR]: 15862,
        [NAMES.ARTA]: 15368,
        [NAMES.PPO]: 892,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12193,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20680
    },
    "Jul 17 2024": {
        [NAMES.TROOPS]: 562510,
        [NAMES.TANKS]: 8238,
        [NAMES.BMP_BTR]: 15871,
        [NAMES.ARTA]: 15411,
        [NAMES.PPO]: 893,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12224,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20741
    },
    "Jul 18 2024": {
        [NAMES.TROOPS]: 563640,
        [NAMES.TANKS]: 8245,
        [NAMES.BMP_BTR]: 15883,
        [NAMES.ARTA]: 15465,
        [NAMES.PPO]: 893,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12274,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20833
    },
    "Jul 19 2024": {
        [NAMES.TROOPS]: 564620,
        [NAMES.TANKS]: 8250,
        [NAMES.BMP_BTR]: 15902,
        [NAMES.ARTA]: 15520,
        [NAMES.PPO]: 894,
        [NAMES.JETS]: 361,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12316,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20909
    },
    "Jul 20 2024": {
        [NAMES.TROOPS]: 565610,
        [NAMES.TANKS]: 8257,
        [NAMES.BMP_BTR]: 15917,
        [NAMES.ARTA]: 15542,
        [NAMES.PPO]: 895,
        [NAMES.JETS]: 362,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12350,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 20968
    },
    "Jul 21 2024": {
        [NAMES.TROOPS]: 566710,
        [NAMES.TANKS]: 8266,
        [NAMES.BMP_BTR]: 15963,
        [NAMES.ARTA]: 15586,
        [NAMES.PPO]: 896,
        [NAMES.JETS]: 362,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12402,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21036
    },
    "Jul 22 2024": {
        [NAMES.TROOPS]: 567760,
        [NAMES.TANKS]: 8284,
        [NAMES.BMP_BTR]: 15980,
        [NAMES.ARTA]: 15637,
        [NAMES.PPO]: 900,
        [NAMES.JETS]: 362,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12475,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21138
    },
    "Jul 23 2024": {
        [NAMES.TROOPS]: 568980,
        [NAMES.TANKS]: 8288,
        [NAMES.BMP_BTR]: 16000,
        [NAMES.ARTA]: 15694,
        [NAMES.PPO]: 901,
        [NAMES.JETS]: 362,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12511,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21202
    },
    "Jul 24 2024": {
        [NAMES.TROOPS]: 570120,
        [NAMES.TANKS]: 8302,
        [NAMES.BMP_BTR]: 16013,
        [NAMES.ARTA]: 15759,
        [NAMES.PPO]: 902,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12544,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21279
    },
    "Jul 25 2024": {
        [NAMES.TROOPS]: 571350,
        [NAMES.TANKS]: 8313,
        [NAMES.BMP_BTR]: 16039,
        [NAMES.ARTA]: 15804,
        [NAMES.PPO]: 904,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12610,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21358
    },
    "Jul 26 2024": {
        [NAMES.TROOPS]: 572300,
        [NAMES.TANKS]: 8320,
        [NAMES.BMP_BTR]: 16050,
        [NAMES.ARTA]: 15840,
        [NAMES.PPO]: 904,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12683,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21414
    },
    "Jul 27 2024": {
        [NAMES.TROOPS]: 573510,
        [NAMES.TANKS]: 8331,
        [NAMES.BMP_BTR]: 16074,
        [NAMES.ARTA]: 15885,
        [NAMES.PPO]: 905,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12736,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21500
    },
    "Jul 28 2024": {
        [NAMES.TROOPS]: 574690,
        [NAMES.TANKS]: 8344,
        [NAMES.BMP_BTR]: 16095,
        [NAMES.ARTA]: 15936,
        [NAMES.PPO]: 906,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12766,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21572
    },
    "Jul 29 2024": {
        [NAMES.TROOPS]: 576000,
        [NAMES.TANKS]: 8356,
        [NAMES.BMP_BTR]: 16103,
        [NAMES.ARTA]: 16010,
        [NAMES.PPO]: 906,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12805,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21634
    },
    "Jul 30 2024": {
        [NAMES.TROOPS]: 577060,
        [NAMES.TANKS]: 8381,
        [NAMES.BMP_BTR]: 16141,
        [NAMES.ARTA]: 16056,
        [NAMES.PPO]: 906,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12832,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21687
    },
    "Jul 31 2024": {
        [NAMES.TROOPS]: 578120,
        [NAMES.TANKS]: 8390,
        [NAMES.BMP_BTR]: 16161,
        [NAMES.ARTA]: 16086,
        [NAMES.PPO]: 907,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12853,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21739
    },
    "Aug 01 2024": {
        [NAMES.TROOPS]: 579490,
        [NAMES.TANKS]: 8399,
        [NAMES.BMP_BTR]: 16203,
        [NAMES.ARTA]: 16110,
        [NAMES.PPO]: 907,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 12968,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21816
    },
    "Aug 02 2024": {
        [NAMES.TROOPS]: 580590,
        [NAMES.TANKS]: 8402,
        [NAMES.BMP_BTR]: 16211,
        [NAMES.ARTA]: 16161,
        [NAMES.PPO]: 907,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13005,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21872
    },
    "Aug 03 2024": {
        [NAMES.TROOPS]: 581760,
        [NAMES.TANKS]: 8406,
        [NAMES.BMP_BTR]: 16238,
        [NAMES.ARTA]: 16217,
        [NAMES.PPO]: 908,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13057,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 21926
    },
    "Aug 04 2024": {
        [NAMES.TROOPS]: 582910,
        [NAMES.TANKS]: 8411,
        [NAMES.BMP_BTR]: 16255,
        [NAMES.ARTA]: 16276,
        [NAMES.PPO]: 911,
        [NAMES.JETS]: 363,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13103,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22006
    },
    "Aug 05 2024": {
        [NAMES.TROOPS]: 584090,
        [NAMES.TANKS]: 8417,
        [NAMES.BMP_BTR]: 16286,
        [NAMES.ARTA]: 16345,
        [NAMES.PPO]: 911,
        [NAMES.JETS]: 365,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13122,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22094
    },
    "Aug 06 2024": {
        [NAMES.TROOPS]: 585140,
        [NAMES.TANKS]: 8421,
        [NAMES.BMP_BTR]: 16294,
        [NAMES.ARTA]: 16384,
        [NAMES.PPO]: 911,
        [NAMES.JETS]: 365,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13158,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22148
    },
    "Aug 07 2024": {
        [NAMES.TROOPS]: 586370,
        [NAMES.TANKS]: 8429,
        [NAMES.BMP_BTR]: 16323,
        [NAMES.ARTA]: 16451,
        [NAMES.PPO]: 911,
        [NAMES.JETS]: 365,
        [NAMES.HELI]: 326,
        [NAMES.UAV]: 13212,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22226
    },
    "Aug 08 2024": {
        [NAMES.TROOPS]: 587510,
        [NAMES.TANKS]: 8431,
        [NAMES.BMP_BTR]: 16332,
        [NAMES.ARTA]: 16487,
        [NAMES.PPO]: 914,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 327,
        [NAMES.UAV]: 13293,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22285
    },
    "Aug 09 2024": {
        [NAMES.TROOPS]: 588540,
        [NAMES.TANKS]: 8434,
        [NAMES.BMP_BTR]: 16341,
        [NAMES.ARTA]: 16536,
        [NAMES.PPO]: 916,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 327,
        [NAMES.UAV]: 13325,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22371
    },
    "Aug 10 2024": {
        [NAMES.TROOPS]: 589700,
        [NAMES.TANKS]: 8441,
        [NAMES.BMP_BTR]: 16350,
        [NAMES.ARTA]: 16605,
        [NAMES.PPO]: 918,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 327,
        [NAMES.UAV]: 13372,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22453
    },
    "Aug 11 2024": {
        [NAMES.TROOPS]: 590920,
        [NAMES.TANKS]: 8447,
        [NAMES.BMP_BTR]: 16363,
        [NAMES.ARTA]: 16663,
        [NAMES.PPO]: 918,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13399,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22524
    },
    "Aug 12 2024": {
        [NAMES.TROOPS]: 592000,
        [NAMES.TANKS]: 8450,
        [NAMES.BMP_BTR]: 16368,
        [NAMES.ARTA]: 16728,
        [NAMES.PPO]: 919,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13472,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22590
    },
    "Aug 13 2024": {
        [NAMES.TROOPS]: 593160,
        [NAMES.TANKS]: 8455,
        [NAMES.BMP_BTR]: 16385,
        [NAMES.ARTA]: 16764,
        [NAMES.PPO]: 920,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13491,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22649
    },
    "Aug 14 2024": {
        [NAMES.TROOPS]: 594400,
        [NAMES.TANKS]: 8476,
        [NAMES.BMP_BTR]: 16402,
        [NAMES.ARTA]: 16821,
        [NAMES.PPO]: 921,
        [NAMES.JETS]: 366,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13548,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22710
    },
    "Aug 15 2024": {
        [NAMES.TROOPS]: 595620,
        [NAMES.TANKS]: 8484,
        [NAMES.BMP_BTR]: 16426,
        [NAMES.ARTA]: 16868,
        [NAMES.PPO]: 922,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13598,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22775
    },
    "Aug 16 2024": {
        [NAMES.TROOPS]: 596950,
        [NAMES.TANKS]: 8496,
        [NAMES.BMP_BTR]: 16461,
        [NAMES.ARTA]: 16927,
        [NAMES.PPO]: 922,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13659,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22848
    },
    "Aug 17 2024": {
        [NAMES.TROOPS]: 598180,
        [NAMES.TANKS]: 8501,
        [NAMES.BMP_BTR]: 16473,
        [NAMES.ARTA]: 16985,
        [NAMES.PPO]: 923,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13714,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22913
    },
    "Aug 18 2024": {
        [NAMES.TROOPS]: 599350,
        [NAMES.TANKS]: 8508,
        [NAMES.BMP_BTR]: 16480,
        [NAMES.ARTA]: 17056,
        [NAMES.PPO]: 923,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13752,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 22992
    },
    "Aug 19 2024": {
        [NAMES.TROOPS]: 600470,
        [NAMES.TANKS]: 8513,
        [NAMES.BMP_BTR]: 16495,
        [NAMES.ARTA]: 17104,
        [NAMES.PPO]: 925,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13809,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23047
    },
    "Aug 20 2024": {
        [NAMES.TROOPS]: 601800,
        [NAMES.TANKS]: 8518,
        [NAMES.BMP_BTR]: 16521,
        [NAMES.ARTA]: 17156,
        [NAMES.PPO]: 926,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13864,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23142
    },
    "Aug 21 2024": {
        [NAMES.TROOPS]: 603010,
        [NAMES.TANKS]: 8522,
        [NAMES.BMP_BTR]: 16542,
        [NAMES.ARTA]: 17216,
        [NAMES.PPO]: 928,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13902,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23221
    },
    "Aug 22 2024": {
        [NAMES.TROOPS]: 604140,
        [NAMES.TANKS]: 8529,
        [NAMES.BMP_BTR]: 16567,
        [NAMES.ARTA]: 17262,
        [NAMES.PPO]: 931,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 13998,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23280
    },
    "Aug 23 2024": {
        [NAMES.TROOPS]: 605330,
        [NAMES.TANKS]: 8533,
        [NAMES.BMP_BTR]: 16599,
        [NAMES.ARTA]: 17307,
        [NAMES.PPO]: 932,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14025,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23329
    },
    "Aug 24 2024": {
        [NAMES.TROOPS]: 606490,
        [NAMES.TANKS]: 8542,
        [NAMES.BMP_BTR]: 16620,
        [NAMES.ARTA]: 17349,
        [NAMES.PPO]: 935,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14064,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23383
    },
    "Aug 25 2024": {
        [NAMES.TROOPS]: 607680,
        [NAMES.TANKS]: 8547,
        [NAMES.BMP_BTR]: 16631,
        [NAMES.ARTA]: 17396,
        [NAMES.PPO]: 936,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14095,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23475
    },
    "Aug 26 2024": {
        [NAMES.TROOPS]: 608820,
        [NAMES.TANKS]: 8551,
        [NAMES.BMP_BTR]: 16648,
        [NAMES.ARTA]: 17443,
        [NAMES.PPO]: 937,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14134,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23555
    },
    "Aug 27 2024": {
        [NAMES.TROOPS]: 610100,
        [NAMES.TANKS]: 8563,
        [NAMES.BMP_BTR]: 16667,
        [NAMES.ARTA]: 17495,
        [NAMES.PPO]: 937,
        [NAMES.JETS]: 367,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14254,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23613
    },
    "Aug 28 2024": {
        [NAMES.TROOPS]: 611190,
        [NAMES.TANKS]: 8568,
        [NAMES.BMP_BTR]: 16681,
        [NAMES.ARTA]: 17522,
        [NAMES.PPO]: 938,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14348,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23654
    },
    "Aug 29 2024": {
        [NAMES.TROOPS]: 612390,
        [NAMES.TANKS]: 8571,
        [NAMES.BMP_BTR]: 16699,
        [NAMES.ARTA]: 17549,
        [NAMES.PPO]: 939,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14369,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23706
    },
    "Aug 30 2024": {
        [NAMES.TROOPS]: 613590,
        [NAMES.TANKS]: 8574,
        [NAMES.BMP_BTR]: 16722,
        [NAMES.ARTA]: 17572,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14453,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23763
    },
    "Aug 31 2024": {
        [NAMES.TROOPS]: 614950,
        [NAMES.TANKS]: 8582,
        [NAMES.BMP_BTR]: 16736,
        [NAMES.ARTA]: 17614,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14471,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23825
    },
    "Sep 01 2024": {
        [NAMES.TROOPS]: 616300,
        [NAMES.TANKS]: 8592,
        [NAMES.BMP_BTR]: 16760,
        [NAMES.ARTA]: 17636,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14507,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23881
    },
    "Sep 02 2024": {
        [NAMES.TROOPS]: 617600,
        [NAMES.TANKS]: 8601,
        [NAMES.BMP_BTR]: 16786,
        [NAMES.ARTA]: 17646,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14537,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23925
    },
    "Sep 03 2024": {
        [NAMES.TROOPS]: 618960,
        [NAMES.TANKS]: 8611,
        [NAMES.BMP_BTR]: 16821,
        [NAMES.ARTA]: 17664,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14573,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 23972
    },
    "Sep 04 2024": {
        [NAMES.TROOPS]: 620350,
        [NAMES.TANKS]: 8618,
        [NAMES.BMP_BTR]: 16848,
        [NAMES.ARTA]: 17694,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14616,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24007
    },
    "Sep 05 2024": {
        [NAMES.TROOPS]: 621550,
        [NAMES.TANKS]: 8624,
        [NAMES.BMP_BTR]: 16869,
        [NAMES.ARTA]: 17725,
        [NAMES.PPO]: 940,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14658,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24044
    },
    "Sep 06 2024": {
        [NAMES.TROOPS]: 622720,
        [NAMES.TANKS]: 8631,
        [NAMES.BMP_BTR]: 16878,
        [NAMES.ARTA]: 17745,
        [NAMES.PPO]: 941,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14732,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24116
    },
    "Sep 07 2024": {
        [NAMES.TROOPS]: 623990,
        [NAMES.TANKS]: 8632,
        [NAMES.BMP_BTR]: 16878,
        [NAMES.ARTA]: 17774,
        [NAMES.PPO]: 942,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14784,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24171
    },
    "Sep 08 2024": {
        [NAMES.TROOPS]: 625260,
        [NAMES.TANKS]: 8636,
        [NAMES.BMP_BTR]: 16897,
        [NAMES.ARTA]: 17804,
        [NAMES.PPO]: 942,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14864,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24218
    },
    "Sep 09 2024": {
        [NAMES.TROOPS]: 626410,
        [NAMES.TANKS]: 8639,
        [NAMES.BMP_BTR]: 16906,
        [NAMES.ARTA]: 17830,
        [NAMES.PPO]: 942,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14897,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24262
    },
    "Sep 10 2024": {
        [NAMES.TROOPS]: 627790,
        [NAMES.TANKS]: 8640,
        [NAMES.BMP_BTR]: 16925,
        [NAMES.ARTA]: 17880,
        [NAMES.PPO]: 942,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14933,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24330
    },
    "Sep 11 2024": {
        [NAMES.TROOPS]: 628930,
        [NAMES.TANKS]: 8642,
        [NAMES.BMP_BTR]: 16938,
        [NAMES.ARTA]: 17936,
        [NAMES.PPO]: 943,
        [NAMES.JETS]: 368,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 14990,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24388
    },
    "Sep 12 2024": {
        [NAMES.TROOPS]: 630200,
        [NAMES.TANKS]: 8653,
        [NAMES.BMP_BTR]: 16955,
        [NAMES.ARTA]: 18009,
        [NAMES.PPO]: 945,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15033,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24481
    },
    "Sep 13 2024": {
        [NAMES.TROOPS]: 631420,
        [NAMES.TANKS]: 8671,
        [NAMES.BMP_BTR]: 17003,
        [NAMES.ARTA]: 18061,
        [NAMES.PPO]: 945,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15113,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24560
    },
    "Sep 14 2024": {
        [NAMES.TROOPS]: 632630,
        [NAMES.TANKS]: 8674,
        [NAMES.BMP_BTR]: 17043,
        [NAMES.ARTA]: 18086,
        [NAMES.PPO]: 946,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15149,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24631
    },
    "Sep 15 2024": {
        [NAMES.TROOPS]: 633800,
        [NAMES.TANKS]: 8680,
        [NAMES.BMP_BTR]: 17063,
        [NAMES.ARTA]: 18109,
        [NAMES.PPO]: 947,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15234,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24684
    },
    "Sep 16 2024": {
        [NAMES.TROOPS]: 634860,
        [NAMES.TANKS]: 8683,
        [NAMES.BMP_BTR]: 17071,
        [NAMES.ARTA]: 18123,
        [NAMES.PPO]: 947,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15263,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24712
    },
    "Sep 17 2024": {
        [NAMES.TROOPS]: 635880,
        [NAMES.TANKS]: 8685,
        [NAMES.BMP_BTR]: 17077,
        [NAMES.ARTA]: 18129,
        [NAMES.PPO]: 947,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15329,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24739
    },
    "Sep 18 2024": {
        [NAMES.TROOPS]: 637010,
        [NAMES.TANKS]: 8691,
        [NAMES.BMP_BTR]: 17080,
        [NAMES.ARTA]: 18154,
        [NAMES.PPO]: 947,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15354,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24784
    },
    "Sep 19 2024": {
        [NAMES.TROOPS]: 638140,
        [NAMES.TANKS]: 8705,
        [NAMES.BMP_BTR]: 17093,
        [NAMES.ARTA]: 18177,
        [NAMES.PPO]: 947,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15417,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24839
    },
    "Sep 20 2024": {
        [NAMES.TROOPS]: 639480,
        [NAMES.TANKS]: 8725,
        [NAMES.BMP_BTR]: 17132,
        [NAMES.ARTA]: 18212,
        [NAMES.PPO]: 949,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15469,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24898
    },
    "Sep 21 2024": {
        [NAMES.TROOPS]: 640920,
        [NAMES.TANKS]: 8746,
        [NAMES.BMP_BTR]: 17170,
        [NAMES.ARTA]: 18270,
        [NAMES.PPO]: 949,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15561,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 24966
    },
    "Sep 22 2024": {
        [NAMES.TROOPS]: 642420,
        [NAMES.TANKS]: 8768,
        [NAMES.BMP_BTR]: 17222,
        [NAMES.ARTA]: 18333,
        [NAMES.PPO]: 949,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15628,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25023
    },
    "Sep 23 2024": {
        [NAMES.TROOPS]: 643750,
        [NAMES.TANKS]: 8784,
        [NAMES.BMP_BTR]: 17252,
        [NAMES.ARTA]: 18414,
        [NAMES.PPO]: 949,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15699,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25102
    },
    "Sep 24 2024": {
        [NAMES.TROOPS]: 645150,
        [NAMES.TANKS]: 8800,
        [NAMES.BMP_BTR]: 17292,
        [NAMES.ARTA]: 18475,
        [NAMES.PPO]: 952,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15764,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25175
    },
    "Sep 25 2024": {
        [NAMES.TROOPS]: 646400,
        [NAMES.TANKS]: 8815,
        [NAMES.BMP_BTR]: 17304,
        [NAMES.ARTA]: 18549,
        [NAMES.PPO]: 952,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15879,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25248
    },
    "Sep 26 2024": {
        [NAMES.TROOPS]: 647800,
        [NAMES.TANKS]: 8825,
        [NAMES.BMP_BTR]: 17348,
        [NAMES.ARTA]: 18620,
        [NAMES.PPO]: 959,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 15946,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25353
    },
    "Sep 27 2024": {
        [NAMES.TROOPS]: 649170,
        [NAMES.TANKS]: 8846,
        [NAMES.BMP_BTR]: 17396,
        [NAMES.ARTA]: 18678,
        [NAMES.PPO]: 961,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16031,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25411
    },
    "Sep 28 2024": {
        [NAMES.TROOPS]: 650640,
        [NAMES.TANKS]: 8860,
        [NAMES.BMP_BTR]: 17438,
        [NAMES.ARTA]: 18733,
        [NAMES.PPO]: 961,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16093,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25411
    },
    "Sep 29 2024": {
        [NAMES.TROOPS]: 651810,
        [NAMES.TANKS]: 8869,
        [NAMES.BMP_BTR]: 17476,
        [NAMES.ARTA]: 18795,
        [NAMES.PPO]: 962,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16186,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25495
    },
    "Sep 30 2024": {
        [NAMES.TROOPS]: 653060,
        [NAMES.TANKS]: 8874,
        [NAMES.BMP_BTR]: 17503,
        [NAMES.ARTA]: 18822,
        [NAMES.PPO]: 963,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16224,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25548
    },
    "Oct 01 2024": {
        [NAMES.TROOPS]: 654430,
        [NAMES.TANKS]: 8883,
        [NAMES.BMP_BTR]: 17547,
        [NAMES.ARTA]: 18855,
        [NAMES.PPO]: 963,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16322,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25621
    },
    "Oct 02 2024": {
        [NAMES.TROOPS]: 655560,
        [NAMES.TANKS]: 8887,
        [NAMES.BMP_BTR]: 17579,
        [NAMES.ARTA]: 18869,
        [NAMES.PPO]: 963,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16348,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25692
    },
    "Oct 03 2024": {
        [NAMES.TROOPS]: 656710,
        [NAMES.TANKS]: 8893,
        [NAMES.BMP_BTR]: 17596,
        [NAMES.ARTA]: 18906,
        [NAMES.PPO]: 964,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16393,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25750
    },
    "Oct 04 2024": {
        [NAMES.TROOPS]: 657940,
        [NAMES.TANKS]: 8908,
        [NAMES.BMP_BTR]: 17627,
        [NAMES.ARTA]: 18965,
        [NAMES.PPO]: 965,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16494,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25820
    },
    "Oct 05 2024": {
        [NAMES.TROOPS]: 659220,
        [NAMES.TANKS]: 8916,
        [NAMES.BMP_BTR]: 17658,
        [NAMES.ARTA]: 19037,
        [NAMES.PPO]: 970,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16529,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 25905
    },
    "Oct 06 2024": {
        [NAMES.TROOPS]: 660470,
        [NAMES.TANKS]: 8919,
        [NAMES.BMP_BTR]: 17679,
        [NAMES.ARTA]: 19092,
        [NAMES.PPO]: 970,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16578,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26006
    },
    "Oct 07 2024": {
        [NAMES.TROOPS]: 661630,
        [NAMES.TANKS]: 8933,
        [NAMES.BMP_BTR]: 17710,
        [NAMES.ARTA]: 19156,
        [NAMES.PPO]: 972,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16643,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26102
    },
    "Oct 08 2024": {
        [NAMES.TROOPS]: 662970,
        [NAMES.TANKS]: 8940,
        [NAMES.BMP_BTR]: 17740,
        [NAMES.ARTA]: 19203,
        [NAMES.PPO]: 973,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16686,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26185
    },
    "Oct 09 2024": {
        [NAMES.TROOPS]: 664120,
        [NAMES.TANKS]: 8944,
        [NAMES.BMP_BTR]: 17751,
        [NAMES.ARTA]: 19222,
        [NAMES.PPO]: 972,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16718,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26240
    },
    "Oct 10 2024": {
        [NAMES.TROOPS]: 665200,
        [NAMES.TANKS]: 8945,
        [NAMES.BMP_BTR]: 17765,
        [NAMES.ARTA]: 19263,
        [NAMES.PPO]: 976,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16767,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26314
    },
    "Oct 11 2024": {
        [NAMES.TROOPS]: 666340,
        [NAMES.TANKS]: 8953,
        [NAMES.BMP_BTR]: 17800,
        [NAMES.ARTA]: 19322,
        [NAMES.PPO]: 976,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16837,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26398
    },
    "Oct 12 2024": {
        [NAMES.TROOPS]: 667630,
        [NAMES.TANKS]: 8962,
        [NAMES.BMP_BTR]: 17827,
        [NAMES.ARTA]: 19381,
        [NAMES.PPO]: 976,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16947,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26513
    },
    "Oct 13 2024": {
        [NAMES.TROOPS]: 668930,
        [NAMES.TANKS]: 8971,
        [NAMES.BMP_BTR]: 17876,
        [NAMES.ARTA]: 19410,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 16992,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26584
    },
    "Oct 14 2024": {
        [NAMES.TROOPS]: 670190,
        [NAMES.TANKS]: 8975,
        [NAMES.BMP_BTR]: 17895,
        [NAMES.ARTA]: 19421,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17009,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26622
    },
    "Oct 15 2024": {
        [NAMES.TROOPS]: 671400,
        [NAMES.TANKS]: 8988,
        [NAMES.BMP_BTR]: 17939,
        [NAMES.ARTA]: 19430,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17019,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26654
    },
    "Oct 16 2024": {
        [NAMES.TROOPS]: 672850,
        [NAMES.TANKS]: 8997,
        [NAMES.BMP_BTR]: 17969,
        [NAMES.ARTA]: 19459,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17050,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26732
    },
    "Oct 17 2024": {
        [NAMES.TROOPS]: 674270,
        [NAMES.TANKS]: 9014,
        [NAMES.BMP_BTR]: 18002,
        [NAMES.ARTA]: 19510,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17104,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26815
    },
    "Oct 18 2024": {
        [NAMES.TROOPS]: 675800,
        [NAMES.TANKS]: 9027,
        [NAMES.BMP_BTR]: 18053,
        [NAMES.ARTA]: 19533,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17152,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26908
    },
    "Oct 19 2024": {
        [NAMES.TROOPS]: 677180,
        [NAMES.TANKS]: 9035,
        [NAMES.BMP_BTR]: 18072,
        [NAMES.ARTA]: 19548,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17230,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26946
    },
    "Oct 20 2024": {
        [NAMES.TROOPS]: 678520,
        [NAMES.TANKS]: 9047,
        [NAMES.BMP_BTR]: 18111,
        [NAMES.ARTA]: 19565,
        [NAMES.PPO]: 978,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17287,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 26987
    },
    "Oct 21 2024": {
        [NAMES.TROOPS]: 680230,
        [NAMES.TANKS]: 9071,
        [NAMES.BMP_BTR]: 18175,
        [NAMES.ARTA]: 19589,
        [NAMES.PPO]: 979,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17333,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27034
    },
    "Oct 22 2024": {
        [NAMES.TROOPS]: 681580,
        [NAMES.TANKS]: 9079,
        [NAMES.BMP_BTR]: 18199,
        [NAMES.ARTA]: 19623,
        [NAMES.PPO]: 981,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17404,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27111
    },
    "Oct 23 2024": {
        [NAMES.TROOPS]: 683040,
        [NAMES.TANKS]: 9088,
        [NAMES.BMP_BTR]: 18229,
        [NAMES.ARTA]: 19674,
        [NAMES.PPO]: 981,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17489,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27217
    },
    "Oct 24 2024": {
        [NAMES.TROOPS]: 684280,
        [NAMES.TANKS]: 9090,
        [NAMES.BMP_BTR]: 18254,
        [NAMES.ARTA]: 19719,
        [NAMES.PPO]: 981,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17597,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27286
    },
    "Oct 25 2024": {
        [NAMES.TROOPS]: 685910,
        [NAMES.TANKS]: 9097,
        [NAMES.BMP_BTR]: 18287,
        [NAMES.ARTA]: 19753,
        [NAMES.PPO]: 982,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17670,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27365
    },
    "Oct 26 2024": {
        [NAMES.TROOPS]: 687600,
        [NAMES.TANKS]: 9109,
        [NAMES.BMP_BTR]: 18332,
        [NAMES.ARTA]: 19782,
        [NAMES.PPO]: 984,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17726,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27460
    },
    "Oct 27 2024": {
        [NAMES.TROOPS]: 689040,
        [NAMES.TANKS]: 9113,
        [NAMES.BMP_BTR]: 18355,
        [NAMES.ARTA]: 19821,
        [NAMES.PPO]: 984,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17799,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27560
    },
    "Oct 28 2024": {
        [NAMES.TROOPS]: 690720,
        [NAMES.TANKS]: 9120,
        [NAMES.BMP_BTR]: 18395,
        [NAMES.ARTA]: 19872,
        [NAMES.PPO]: 984,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17867,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27660
    },
    "Oct 29 2024": {
        [NAMES.TROOPS]: 692080,
        [NAMES.TANKS]: 9129,
        [NAMES.BMP_BTR]: 18404,
        [NAMES.ARTA]: 19917,
        [NAMES.PPO]: 984,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17939,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27749
    },
    "Oct 30 2024": {
        [NAMES.TROOPS]: 693640,
        [NAMES.TANKS]: 9137,
        [NAMES.BMP_BTR]: 18433,
        [NAMES.ARTA]: 19955,
        [NAMES.PPO]: 986,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 17979,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27840
    },
    "Oct 31 2024": {
        [NAMES.TROOPS]: 694950,
        [NAMES.TANKS]: 9156,
        [NAMES.BMP_BTR]: 18450,
        [NAMES.ARTA]: 20013,
        [NAMES.PPO]: 994,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18063,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 27961
    },
    "Nov 01 2024": {
        [NAMES.TROOPS]: 696410,
        [NAMES.TANKS]: 9162,
        [NAMES.BMP_BTR]: 18470,
        [NAMES.ARTA]: 20039,
        [NAMES.PPO]: 994,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18088,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28038
    },
    "Nov 02 2024": {
        [NAMES.TROOPS]: 697680,
        [NAMES.TANKS]: 9170,
        [NAMES.BMP_BTR]: 18487,
        [NAMES.ARTA]: 20076,
        [NAMES.PPO]: 994,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18117,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28114
    },
    "Nov 03 2024": {
        [NAMES.TROOPS]: 699090,
        [NAMES.TANKS]: 9182,
        [NAMES.BMP_BTR]: 18523,
        [NAMES.ARTA]: 20086,
        [NAMES.PPO]: 994,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18187,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28160
    },
    "Nov 04 2024": {
        [NAMES.TROOPS]: 700390,
        [NAMES.TANKS]: 9193,
        [NAMES.BMP_BTR]: 18538,
        [NAMES.ARTA]: 20121,
        [NAMES.PPO]: 994,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18280,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28237
    },
    "Nov 05 2024": {
        [NAMES.TROOPS]: 701650,
        [NAMES.TANKS]: 9208,
        [NAMES.BMP_BTR]: 18581,
        [NAMES.ARTA]: 20150,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18309,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28278
    },
    "Nov 06 2024": {
        [NAMES.TROOPS]: 702900,
        [NAMES.TANKS]: 9214,
        [NAMES.BMP_BTR]: 18592,
        [NAMES.ARTA]: 20170,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18366,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28333
    },
    "Nov 07 2024": {
        [NAMES.TROOPS]: 704300,
        [NAMES.TANKS]: 9224,
        [NAMES.BMP_BTR]: 18612,
        [NAMES.ARTA]: 20194,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18408,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28411
    },
    "Nov 08 2024": {
        [NAMES.TROOPS]: 705880,
        [NAMES.TANKS]: 9233,
        [NAMES.BMP_BTR]: 18661,
        [NAMES.ARTA]: 20226,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18526,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28495
    },
    "Nov 09 2024": {
        [NAMES.TROOPS]: 707540,
        [NAMES.TANKS]: 9238,
        [NAMES.BMP_BTR]: 18697,
        [NAMES.ARTA]: 20249,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18575,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28561
    },
    "Nov 10 2024": {
        [NAMES.TROOPS]: 708890,
        [NAMES.TANKS]: 9249,
        [NAMES.BMP_BTR]: 18726,
        [NAMES.ARTA]: 20280,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18619,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28683
    },
    "Nov 11 2024": {
        [NAMES.TROOPS]: 710660,
        [NAMES.TANKS]: 9253,
        [NAMES.BMP_BTR]: 18766,
        [NAMES.ARTA]: 20314,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18676,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28802
    },
    "Nov 12 2024": {
        [NAMES.TROOPS]: 712610,
        [NAMES.TANKS]: 9276,
        [NAMES.BMP_BTR]: 18847,
        [NAMES.ARTA]: 20352,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18737,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28870
    },
    "Nov 13 2024": {
        [NAMES.TROOPS]: 714380,
        [NAMES.TANKS]: 9300,
        [NAMES.BMP_BTR]: 18896,
        [NAMES.ARTA]: 20408,
        [NAMES.PPO]: 996,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 328,
        [NAMES.UAV]: 18798,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 28966
    }
};
